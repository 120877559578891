import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../pages/auth/UserDetails/user.service';
import { GlobalStorage } from '../../../core/Gloabl/Global';
import { Notification} from '../../../core/Notifications/Notification';
import { environment } from 'src/environments/environment';
import { ServiceService } from 'src/app/module/patient/service.service';
import { NavbarService } from 'src/app/services/navbar.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  username: string;
  userdata: any;
  // useremail: string;

  Image_Url;
  EditProfileRoute: string;
  ViewProfileRoute: string;
  roleName: any;
  hos_id: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private user: UserService,
    private store: GlobalStorage,
    private Notifi:Notification,
    // private service:ServiceService
    private service:NavbarService,
  ) { }

  ngOnInit(): void {
    this.roleName = localStorage.getItem('roleName')
    this.hos_id = sessionStorage.getItem('hos_id')
    
    this.service.currentUserImage.subscribe(data => {
      console.log('Test image')
      console.log(data)
      if (data != undefined) {
      this.Image_Url = data;
      }
      })

    this.service.Get_Userprofile(this.hos_id)
    .subscribe((data)=>{     
      this.userdata = data;
      console.log('user data navbar :' + JSON.stringify(this.userdata))
      // this.Image_Url=this.userdata.imagename;
        /*
      if(this.Image_Url == ''){
        // this.Image_Url=this.userdata.imagename; //{url:'/assets/images/profile.png'}
        this.Image_Url='https://telemedicineapi.medetel.in/Images/'+this.userdata.imagename;
      }
      */
    
      if (this.Image_Url == '') {
        // this.Image_Url=this.userdata.imagename; //{url:'/assets/images/profile.png'}
        this.Image_Url = `${environment.Image_Url}` + 'Images/' + this.userdata.imagename;
        console.log('img ur :' + this.Image_Url)
      }
      if(this.username ==''){
        this.username=''
      }
      if(this.userdata.rolename==='Patient'){
        this.EditProfileRoute='/base/patient/edit-profile';
        this.ViewProfileRoute='/base/patient/profile';
      }
      else if(this.userdata.rolename==='Doctor'){
        // this.Notifi.successmsg(this.userdata.firstName + " logged Successfully.");  
        // this.EditProfileRoute='/base/doctor/doctoreditprofile';
        this.ViewProfileRoute='/base/doctor/doctorprofile';
      }
      if(this.userdata.firstName ==="Doctor Data not found in the Hospital"){
        this.Notifi.errorsmsg('Doctor Data not found in Selected Hospital');
        this.user.logout();
        this.router.navigate(['/auth/login-doctor']);
      }
    })
   
  }

 

  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    console.log(this.userdata.userName)
    console.log(this.userdata.rolename)
    e.preventDefault();
    this.user.logout();
    //PATIENT
    if(this.userdata.rolename==='Patient'){
      this.router.navigate(['/auth/login']);
    }
    //DOCTOR (ONLINE)
    else if(this.userdata.rolename==='Doctor'){
      sessionStorage.removeItem('hos_id');
      this.router.navigate(['/auth/login-doctor']);
    }
    
  }

}