import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from './user.profile';
import { IProfile } from './user.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../../../environments/environment';
import { LoginComponent } from '../login/login.component';
@Injectable()
export class UserService {
    redirectUrl: string;
    errorMessage: string;
    
    readonly rootUrl = 'http://localhost:64832';  //API
    //readonly rootUrl = 'http://localhost:64832';
    //readonly rootUrl = 'http://crmapi.esdinfra.com';  

    httpOptions: any;  
    constructor(private http: HttpClient,private authProfile: UserProfile) {      
      
      let username=localStorage.getItem('username');
      let password=localStorage.getItem('password');

      let accesstoken = localStorage.getItem('access_token');
      if (accesstoken != null && accesstoken != '' && typeof (accesstoken) != undefined) {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + accesstoken,
            'No-Auth': 'True'
          })
        };
      }


    }   
    
    isAuthenticated() {
       
    }
    isAuthorized() {
      let profile = this.authProfile.getProfile();
      var validToken = profile.access_token != "" && profile.access_token != null;
     var isTokenExpired = this.isTokenExpired(profile);
      return validToken //&& !isTokenExpired;
    }
    
    isTokenExpired(profile: IProfile) {
      var expiration = new Date(profile.expires_in)
      return expiration < new Date();
    }

    Post_Patient_register(data){
      return this.http.post(`${environment.API_Base_URL}`+`Authentication/ExternalRegister`,data)
    }    

    logout(): void {
        this.authProfile.resetProfile();
    }
   
      
}